import { Button, Typography } from "antd";
import { DeleteFilled, FileTextFilled, EditFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

export const useQrCodesTableColumn = (showModal) => {
  const navigate = useNavigate();

  return [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      search: false,
    },
    {
      key: "isActive",
      title: "Is Active",
      dataIndex: "isActive",
      render: (record) => (record ? "True" : "False"),
      valueEnum: {
        true: {
          text: "True",
        },
        false: {
          text: "False",
        },
      },
    },
    {
      key: "point",
      title: "Point",
      dataIndex: "point",
      search: false,
    },
    {
      key: "scanCount",
      title: "Scan Count",
      dataIndex: "scanCount",
      search: false,
    },
    {
      key: "maxScanPerPhone",
      title: "Max Scan Per Phone",
      dataIndex: "maxScanPerPhone",
      search: false,
    },
    {
      key: "maxScanTime",
      title: "Max Scan Time",
      dataIndex: "maxScanTime",
      search: false,
    },

    {
      key: "isInfiniteClaim",
      title: "Infinite Claim",
      dataIndex: "isInfiniteClaim",
      render: (record) => (record ? "True" : "False"),
      search: false,
    },

    {
      key: "createdAt",
      title: "Created At",
      dataIndex: "createdAt",
      valueType: "dateTimeRange",
      search: false,
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record.createdAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
    {
      key: "updatedAt",
      title: "Updated At",
      dataIndex: "updatedAt",
      hideInTable: true,
      search: false,
      render: (_, record) => {
        return <Text>{dayjs(record.updatedAt).fromNow()}</Text>;
      },
    },
    {
      key: "actions",
      title: "Actions",
      search: false,
      width: 100,
      render: (_, record) => [
        <div key={record.id} className="actions-wrapper">
          <Button
            className="icon-button"
            icon={<FileTextFilled />}
            onClick={() => navigate(`/qr-codes/${record?.id}`)}
          />

          <Button
            className="icon-button"
            icon={<EditFilled />}
            onClick={() => navigate(`/qr-codes/${record?.id}/edit`)}
          />
          {showModal && (
            <Button
              className="icon-button"
              icon={<DeleteFilled />}
              onClick={() => showModal(record)}
            />
          )}
        </div>,
      ],
    },
  ];
};
