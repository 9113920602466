import {
  SkinOutlined,
  UserOutlined,
  RollbackOutlined,
  ProfileOutlined,
  MailOutlined,
  SettingOutlined,
  SubnodeOutlined,
  UsergroupAddOutlined,
  UnorderedListOutlined,
  FileTextOutlined,
  BankOutlined,
  PercentageOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";

const menuData = {
  route: {
    path: "/",
    routes: [
      {
        path: "/clubs",
        name: "Clubs",
        icon: <SkinOutlined />,
      },
      {
        path: "/stadiums",
        name: "Stadiums",
        icon: <BankOutlined />,
      },
      {
        path: "/events",
        name: "Events",
        icon: <ProfileOutlined />,
      },
      {
        path: "/sub-categories",
        name: "Sub Categories",
        icon: <SubnodeOutlined />,
      },
      {
        name: "Sponsors",
        path: "/sponsors",
        icon: <UsergroupAddOutlined />,
      },
      {
        name: "Users",
        path: "/users",
        icon: <UserOutlined />,
      },
      {
        name: "OTPs",
        path: "/sms-otp",
        icon: <MailOutlined />,
      },
      {
        name: "Coupon",
        path: "/coupon",
        icon: <PercentageOutlined />,
      },
      {
        name: "Payment logs",
        path: "/payment-logs",
        icon: <UnorderedListOutlined />,
      },
      {
        name: "Order logs",
        path: "/order-logs",
        icon: <FileTextOutlined />,
      },
      {
        name: "Qr Codes",
        path: "/qr-codes",
        icon: <QrcodeOutlined />,
      },
      {
        name: "Settings",
        path: "/settings",
        icon: <SettingOutlined />,
        children: [
          {
            name: "Sliders",
            path: "/sliders",
          },
          {
            name: "System",
            path: "/system",
          },
          {
            name: "Odoo",
            path: "/odoo",
          },
          {
            name: "Event Game",
            path: "/event-game",
          },
        ],
      },
      {
        name: "Logout",
        path: "/logout",
        icon: <RollbackOutlined />,
      },
    ],
  },
  location: {
    pathname: "/",
  },
};

export default menuData;
