import React, { useCallback, useEffect, useState } from "react";
import {
  PageContainer,
  ProForm,
  ProFormDigit,
  ProFormSwitch,
} from "@ant-design/pro-components";
import { notification } from "antd";
import { getSettings, patchSettings } from "../../../api";

const EventGame = () => {
  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement, type) => {
    switch (type) {
      case 1:
        api.error({
          message: "Update settings",
          description: "Something went wrong!",
          placement,
          duration: 3,
        });
        break;

      default:
        api.success({
          message: "Update settings",
          description: "Update settings successfully.",
          placement,
          duration: 3,
        });
        break;
    }
  };

  const fetchSettings = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getSettings();
      if (Array.isArray(response)) {
        response.forEach((element) => {
          if (element?.key === "EVENT_GAME") {
            return setFormData((prevState) => ({
              ...prevState,
              eventGamePoints: element?.content?.points,
              eventGameToggle: element?.content?.isEnable,
              eventGameMaxClaimTime: element?.content?.maxClaimTime,
            }));
          }
          return;
        });
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSubmit = async (values) => {
    try {
      const customizeParams = [
        {
          key: "EVENT_GAME",
          content: {
            points: values?.eventGamePoints,
            isEnable: values?.eventGameToggle,
            maxClaimTime: values?.eventGameMaxClaimTime,
          },
        },
      ];

      const response = await patchSettings(customizeParams);

      if (response?.status === 200) {
        openNotification("bottomLeft");
      }
    } catch (error) {
      console.log("Error", error);
      openNotification("bottomLeft", 1);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  return (
    <PageContainer title="Event Game Settings">
      {contextHolder}

      {formData && !isLoading && (
        <ProForm
          name="settingsForm"
          layout="horizontal"
          onFinish={handleSubmit}
          initialValues={formData}
        >
          <ProForm.Group>
            <div className="form-fields-wrapper">
              <ProFormDigit
                name="eventGamePoints"
                width={"lg"}
                min={0}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Event Game Points is required! My friends
                      </p>
                    ),
                  },
                ]}
                label="Points"
                placeholder="Enter points"
              />
              <ProFormDigit
                name="eventGameMaxClaimTime"
                width={"lg"}
                min={0}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Event Game Points is required! My friends
                      </p>
                    ),
                  },
                ]}
                label="Max Claim Time"
                placeholder="Enter times"
              />
              <ProFormSwitch name="eventGameToggle" label="Event Game Toggle" />
            </div>
          </ProForm.Group>
        </ProForm>
      )}
    </PageContainer>
  );
};

export default EventGame;
