import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageContainer, ProDescriptions } from "@ant-design/pro-components";
import dayjs from "dayjs";
import { Button, Card, Image } from "antd";
import { getQrCodesData } from "../../../api";
import QRCode from "qrcode";

const QR_BASE_URL = process.env.REACT_APP_CLIENT_BASE_URL;

const QrCodeDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [qrCodesData, setQrCodesData] = useState();
  const [urlQrCode, setUrlQrCode] = useState();

  const fetchQrCodesData = useCallback(async () => {
    try {
      const response = await getQrCodesData(id);

      setQrCodesData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  useEffect(() => {
    fetchQrCodesData();
  }, [fetchQrCodesData]);

  useEffect(() => {
    if (qrCodesData && qrCodesData.id) {
      QRCode.toDataURL(`${QR_BASE_URL}/qr-codes/${qrCodesData.id}`, {
        width: 800,
      })
        .then((url) => {
          setUrlQrCode(url);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return;
  }, [qrCodesData]);

  return (
    <>
      {qrCodesData && (
        <PageContainer
          title={`Qr Code details`}
          extra={[
            <Button
              key="edit-waiting-room"
              type="primary"
              onClick={() => navigate(`edit`)}
            >
              Edit Qr Code
            </Button>,
          ]}
        >
          <Card title="Qr Code Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item label="Qr Code ID" valueType="text">
                {qrCodesData?.id}
              </ProDescriptions.Item>
              <ProDescriptions.Item label="Qr Code name" valueType="text">
                {qrCodesData?.name}
              </ProDescriptions.Item>
              <ProDescriptions.Item label="Title Ar" valueType="text">
                {qrCodesData?.titleAr}
              </ProDescriptions.Item>
              <ProDescriptions.Item label="Title En" valueType="text">
                {qrCodesData?.titleEn}
              </ProDescriptions.Item>
              <ProDescriptions.Item label="Is Active" valueType="text">
                {qrCodesData?.isActive ? "True" : "False"}
              </ProDescriptions.Item>
              <ProDescriptions.Item label="Point" valueType="text">
                {qrCodesData?.point}
              </ProDescriptions.Item>
              <ProDescriptions.Item label="Scan Count" valueType="number">
                {qrCodesData?.scanCount}
              </ProDescriptions.Item>
              <ProDescriptions.Item
                label="Max Scan Per Phone"
                valueType="number"
              >
                {qrCodesData?.maxScanPerPhone}
              </ProDescriptions.Item>
              <ProDescriptions.Item label="Max Scan Time" valueType="number">
                {qrCodesData?.maxScanTime}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Description Ar" valueType="text">
                {qrCodesData?.descriptionAr}
              </ProDescriptions.Item>
              <ProDescriptions.Item label="Description En" valueType="number">
                {qrCodesData?.descriptionEn}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Created at">
                {dayjs(qrCodesData?.createdAt).format("YYYY-MM-DD HH:mm")}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="QR code">
                <Image width={300} src={urlQrCode} />
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>
        </PageContainer>
      )}
    </>
  );
};

export default QrCodeDetails;
