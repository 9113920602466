import { Button, Modal } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import { createQueryStringUrl } from "../../utils/createQueryStringUrl";
import { PageContainer, ProTable } from "@ant-design/pro-components";
import { PAGINATION_INITIAL_VALUES } from "../../utils/constant";
import { useQrCodesTableColumn } from "../../components/Columns/QrCodes";
import { deleteQrCodes, getQrCodes } from "../../api";

const QrCodes = () => {
  const navigate = useNavigate();
  const [form] = useForm();

  const [pagination, setPagination] = useState(PAGINATION_INITIAL_VALUES);
  const [qrCodes, setQrCodes] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState();
  const [isShowModal, setIsShowModal] = useState(false);
  const [filterValues, setFilterValues] = useState({});

  const showModal = (record) => {
    setSelectedRecord(record);
    setIsShowModal(true);
  };

  const handleCancel = () => setIsShowModal(false);

  const fetchQrCodes = useCallback(async () => {
    try {
      createQueryStringUrl(filterValues);
      form.setFieldsValue(filterValues);

      const params = {
        ...filterValues,
        page: pagination.currentPage,
        limit: pagination.pageSize,
      };

      const response = await getQrCodes(params);

      setQrCodes(response.results);
      setPagination((prevState) => ({
        ...prevState,
        totalItems: response.total,
      }));
    } catch (error) {
      console.log("Error", error);
    }
  }, [filterValues, form, pagination.currentPage, pagination.pageSize]);

  const handleSubmitModal = async () => {
    try {
      const response = await deleteQrCodes(selectedRecord?.id);

      if (response.status === 200) {
        await fetchQrCodes();
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      handleCancel();
    }
  };

  const handleSubmitFilter = (params) => {
    const formValues = form.getFieldsValue();

    setFilterValues({
      ...filterValues,
      ...formValues,
    });

    setPagination((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
  };

  const handleResetFilter = () => {
    setFilterValues({});
    setPagination((prevState) => ({
      ...prevState,
      currentPage: 1,
    }));
  };

  useEffect(() => {
    fetchQrCodes();
  }, [fetchQrCodes]);

  const columns = useQrCodesTableColumn(showModal);

  return (
    <PageContainer title="All Qr Codes">
      {isShowModal && (
        <Modal
          open={isShowModal}
          onOk={() => handleSubmitModal()}
          onCancel={handleCancel}
          centered
        >
          <div className="delete-content">
            <ExclamationCircleFilled style={{ fontSize: "48px" }} />

            <p>Are you sure to delete this record?</p>
          </div>
        </Modal>
      )}

      <ProTable
        rowKey="id"
        request={({ pageSize }, filter) => {
          setPagination((prevState) => ({
            ...prevState,
            pageSize,
          }));
          return {
            filter,
          };
        }}
        onSubmit={(params) => handleSubmitFilter(params)}
        onReset={handleResetFilter}
        // onRow={(record) => {
        //   return {
        //     onClick: () => {
        //       navigate(`${record?.id}`);
        //     },
        //   };
        // }}
        scroll={{ x: 800, y: 500 }}
        columns={columns}
        dataSource={qrCodes}
        pagination={{
          pageSize: pagination.pageSize,
          total: pagination.totalItems,
          onChange: (page) =>
            setPagination((prevState) => ({
              ...prevState,
              currentPage: page,
            })),
        }}
        toolBarRender={() => [
          <Button
            key="button"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate("/qr-codes/new");
            }}
            type="primary"
          >
            New Qr Codes
          </Button>,
        ]}
        search={{
          form: form,
          labelWidth: "auto",
        }}
        // search={false}
      ></ProTable>
    </PageContainer>
  );
};

export default QrCodes;
